<template>
<v-system-bar height="45px" color="white" id="header" app>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row class="d-flex justify-start">
            <!-- <img :src="logoUrl" style="width:150px;height:30px;object-fit:contain;"> -->
            <img :src="require('@/assets/logo/logo-line-okr02.png')" style="width:150px;height:30px;object-fit:contain;">
            <v-spacer></v-spacer>
            <!-- <div class="message-center" @click="goMessage">
              <img :src="require('@/assets/images/bell.svg')" style="vertical-align:middle;width:30px;height:30px;object-fit:contain;background:#9e9e9e;border-radius:50%;">
              <div class="message-num">99+</div>
            </div> -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" plain>
                  <v-container>
                    <v-row class="d-flex align-end">
                      <v-col>
                        <!-- <v-avatar size="30" color="green"><span class="text-caption">{{authUser.employeeName.slice(0,2)}}</span></v-avatar> -->
                        <UserAvatar :fullName="authUser.employeeName" :name="authUser.employeeName.slice(0,1)" width='30px' height='30px' :userId="authUser.id" />
                      </v-col>
                      <v-col class="pt-5 pl-0">{{authUser.employeeName}}
                        <v-icon>mdi-menu-down</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in menuItems" :key="index">
                  <v-list-item-title>
                    <v-btn plain :to="item.link">{{ item.title }}</v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn plain @click="logout">退出登录</v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</v-system-bar>
</template>

<script>
import Images from '@/constants/image.js'

import {
  mapGetters,
  mapActions
} from "vuex";
import {
  Routes
} from '@/constants/routes';
import endpoints from "@/config/endpoints";
export default {
  name: "Header",
  components: {
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
    MessagePage: () => import('@/components/message/MessagePage.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    menuItems: function () {
      let temp = null;
      //if (this.authUser.user_role == 'admin' || this.authUser.user_role == 'creator') {
      if (this.authUser.user_role == 'admin' || this.authUser.user_role == 'editor') {
        temp = [
          // {
          //   title: '个人主页',
          // },
          {
            title: '个人设置',
            link: Routes.INFO_SETTING_PAGE
          },
          {
            title: '企业管理后台',
            link: Routes.ADMIN_EMPLOYEE_PAGE
          },
          // {
          //   title: '开启高级功能',
          //   link: Routes.ADMIN_EMPLOYEE_PAGE
          // },
          {
            title: '企业管理规定',
            link: Routes.OKR_SETTING_PAGE
          }]
      } else {
        temp = [
          // {
          //   title: '个人主页',
          // },
          {
            title: '个人设置',
            link: Routes.INFO_SETTING_PAGE
          }
          // {
          //   title: '企业管理后台',
          // },
          // {
          //   title: '企业管理规定',
          // }
        ]
      }
      return temp;
    }
  },

  data() {
    return {
      images: Images,
      logoUrl: '',
    }
  },
  methods:{

    ...mapActions('auth', ['logout']),
    ...mapActions('app', ['getSettingCall']),

    //消息页
    goMessage(){
      
      this.$router.push({
        path: Routes.OKR_MESSAGE_PAGE,
      });
    },
  },
  mounted() {
    // this.getSettingCall({
    //   st_category_id: 100,
    //   st_category_type: 100
    // }).then(res => {
    //   this.logoUrl = `${endpoints.FILE_DOWNLOAD}/${JSON.parse(res).file_id}`
    // });

    
  }
};
</script>

<style>
#header {
  border-bottom: 1px solid grey !important;
}

.com_logo {
  width: 117px;
  height: 30px;
  display: block;
  margin-top: 0px;
}

.com_name {
  display: block;
  float: left;
  margin-left: 15px;
  line-height: 36px;
  color: #66757f;
  font-size: 14px;
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.com_des {
  width: 122px;
  height: 22px;
  margin-left: 15px;
}

.chat_room {
  margin-top: 7px;
}

.tita {
  line-height: 40px;
  font-size: 12px;
  color: #2879ff;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.chat_room_2 {
  margin-top: 7px;
  margin-right: 5px;
  color: #2879ff;
}

#red_ch {
  margin-left: 10px;
}

.chat_room_3 {
  margin-top: 10px;
  margin-right: 5px;
  color: #2879ff;
}

#chat_3 {
  margin-left: 20px;
  margin-right: 2px;
  vertical-align: middle;
}
.message-center{
  position:relative;
  /* top: 4px; */
}
.message-center:hover{
  cursor: pointer;
}
.message-num{
  position:absolute;
  top:-10px;
  right:-11px;
  background:red;
  padding:0px 3px;
  height:20px;
  border-radius:50%;
  font-size:12px;
  color:#fff;
  box-shadow: 1px 4px 4px #888888;
}
</style>
